<template>
  <div>
    <v-card>
      <TitleBar @close="$emit('cancel')">Create Environment</TitleBar>

      <v-card-text>
        <v-form>
          <InputTextField label="NAME" v-model="$v.environment.name" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
        <PrimaryButton @click="createEnvironment">Create</PrimaryButton>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {

  data() {
    return {
      environment: {
        name: null
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    environment: {
      name: { required },
    },
  },

  methods: {
    async createEnvironment() {
      if (this.$v.environment.$invalid) {
        this.$v.environment.$touch()
        return
      }

      this.$store.commit("set", ["modalSpinnerText", "Creating..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      let configHeader = {
        headers: {
          Authorization: "Bearer " + this.$cookies.get("requestToken"),
        },
      };
      let data = {
        name: this.environment.name,
      };

      try {
        const added = await this.$http.post("/api/environment", data, configHeader).then((response)=>{
          return response.data
        }).catch(()=>{
          return false;
        });

        if(added){
          this.$emit('done',added);
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }

    }
  }

}
</script>
