<!--========================
Author by: Sreng Veasna
Created on: 26.09.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <div>
    <!-- Breadcrumbs -->

    <v-card class="ma-4" flat>
      <!-- <v-alert
        v-show="warningModalState"
        text
        :type="warningModalColor"
        transition="fade-transition"
      >
        {{ warningMessage }}
      </v-alert> -->
      <TitleBar>
        Environments
        <template #right-items>
          <PrimaryButton @click="addEnvironment">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>
      <v-data-table
        :headers="headers"
        :items="environments"
        :search="search"
        @click:row="rowClicked"
      >
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <v-btn icon @click.stop="confirmDelete(item.idenvironment)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="400px" v-model="showHideModal">
      <v-card>
        <v-card-title>Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showHideModal = false">Discard</v-btn>
          <v-btn @click="deleteEnvironment" color="warning">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- :key to force v-dialog to re-render -->
    <v-dialog
      max-width="600px"
      v-model="showEnvironmentModal"
      :key="`show-${showEnvironmentModal}`"
    >
      <EnvironmentSheet
        :environmentId="viewEnvironmentId"
        @done="environmentSaved"
        @cancel="setViewEnvironment(null)"
      />
    </v-dialog>

    <v-dialog
      max-width="600px"
      v-model="showNewEnvironmentModal"
      :key="`new-${showNewEnvironmentModal}`"
    >
      <NewEnvironmentSheet
        @done="environmentAdded"
        @cancel="showNewEnvironmentModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>

import NewEnvironmentSheet from '../../components/Environment/NewEnvironmentSheet.vue'
import EnvironmentSheet from '../../components/Environment/EnvironmentSheet.vue'

export default {
  name: "HomeEnvironment",
  components: { NewEnvironmentSheet, EnvironmentSheet },
  data: function () {
    return {
      search: "",
      showHideModal: false,
      envIdToDelete: "",
      environments: [],
      warningMessage: "Something went wrong",
      warningModalState: false,
      warningModalColor: "error",
      headers: [
        { value: "name", text: "Name" },
        {
          value: "actions",
          text: null,
          width: "40px",
          sortable: false,
          filterable: false,
        },
      ],

      showNewEnvironmentModal: false,

      showEnvironmentModal: false,
      viewEnvironmentId: null
    };
  },
  mounted() {
    this.getEnvironments();
  },
  methods: {
    showAlertMessage(message = "Something went wrong", color = "error") {
      this.warningMessage = message;
      this.warningModalColor = color;

      this.warningModalState = true;
      setTimeout(() => {
        this.warningModalState = false;
      }, 3000);
    },
    async getEnvironments() {
      this.$store.commit("toggle", "showHideModalSpinner");
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };

      try {
        const response = await this.$http.get("/api/environment", { headers });
        this.environments = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    addEnvironment() {
      this.showNewEnvironmentModal = true
    },
    environmentAdded() {
      this.showNewEnvironmentModal = false
      this.getEnvironments()
    },
    rowClicked(environment) {
      this.setViewEnvironment(environment.idenvironment)
    },
    setViewEnvironment(environmentId) {
      this.viewEnvironmentId = environmentId
      this.showEnvironmentModal = (environmentId != null)
    },
    environmentSaved() {
      this.setViewEnvironment(null)
      this.getEnvironments()
    },
    confirmDelete(IdEnvironment) {
      this.showHideModal = true;
      this.envIdToDelete = IdEnvironment;
    },
    async deleteEnvironment() {
      this.showHideModal = false;
      this.$store.commit("set", ["modalSpinnerText", "Deleting..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        await this.$http.delete("/api/environment/" + this.envIdToDelete, { headers });
        this.getEnvironments();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
  },
};
</script>
