<template>
  <div>
    <v-card v-if="environment">
      <TitleBar @close="$emit('cancel')">{{ environment.name }}</TitleBar>

      <v-card-text>
        <v-form>
          <InputTextField label="NAME" v-model="$v.environment.name" />
        </v-form>

        <v-sheet class="mt-4">
          <SectionBar :inset="false">
            Connections
            <template #left-items>
              <v-btn
                icon
                color="primary"
                @click="showAddConnectionModal = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </SectionBar>
          <v-simple-table v-if="environment.connections.length != 0">
            <template>
              <thead>
                <tr>
                  <th class="grey--text text--darken-1">Connection</th>
                  <th class="grey--text text--darken-1">Tag</th>
                  <!-- <th width="50px" /> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="connection in environment.connections"
                  :key="connection.idconnection + connection.tagname"
                >
                  <td>
                    {{ connection.connection_name }}
                  </td>
                  <td>
                    {{ connection.tagname }}
                  </td>
                  <!-- <td>
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
        <PrimaryButton @click="saveEnvironment" :disabled="!$v.$anyDirty"
          >Save</PrimaryButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showAddConnectionModal" max-width="500">
      <v-card>
        <v-card-title> Add Connection </v-card-title>
        <v-card-text>
          <SelectTextField
            label="CONNECTION"
            v-model="selectedConnectionId"
            :items="availableConnections"
            :item-text="(item) => `${item.name} (${item.platformName})`"
            item-value="idconnection"
          />
          <InputTextField label="TAG NAME" v-model="tagName" />
        </v-card-text>
        <v-card-actions>
          <DismissButton @click="showAddConnectionModal = false"
            >Cancel</DismissButton
          >
          <PrimaryButton @click="addConnection" :disabled="!canAddConnection"
            >OK</PrimaryButton
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["environmentId"],

  data() {
    return {
      environment: null,

      showAddConnectionModal: null,
      availableConnections: null,
      selectedConnectionId: null,
      tagName: null
    };
  },

  computed: {
    canAddConnection() {
      return this.tagName && this.selectedConnectionId
    }
  },

  watch: {
    showAddConnectionModal(newValue) {
      if (newValue == false) {
        this.selectedConnectionId = null
        this.tagName = null
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    environment: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.getEnvironment();
    this.getAvailableConnections()
  },
  methods: {

    async getEnvironment() {
      this.$store.commit("toggle", "showHideModalSpinner");
      try {
        this.environment = await this._getEnvironment()
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },

    async _getEnvironment() {
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
        Include: 'form,connection'
      }

      const response = await this.$http.get("/api/environment/" + this.environmentId, {headers})
      return response.data
    },

    async getAvailableConnections() {
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      try {
        const response = await this.$http.get('/api/connection', {headers})
        this.availableConnections = response.data
      } catch (error) {
        console.log(error)
      }
    },

    async addConnection() {
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      }
      let data = {
        idenvironment: this.environment.idenvironment,
        idconnection: this.selectedConnectionId,
        tagname: this.tagName
      }

      //await this.$helpers.refreshAccessToken();
      try {
        await this.$http.post('/api/environment/connection_environment', data, { headers })
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner")
      }

      this.showAddConnectionModal = false
      this.refreshEnvConnection()
    },

    async refreshEnvConnection() {
      try {
        this.environment.connections = (await this._getEnvironment()).connections
      } catch (error) {
        console.log(error);
      }
    },

    async saveEnvironment() {
      //await this.$helpers.refreshAccessToken();
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      let data = {
        name: this.environment.name,
      };

      try {
        await this.$http.patch("/api/environment/" + this.environmentId, data, { headers })
        this.$emit('done')
      } catch (error) {
        console.log(error);
      }
    }
  },
}
</script>
